import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC, useEffect } from "react";

import { ExportStoryboardModal } from "@/features/export-storyboard-modal";
import { RenderTable, StoryboardView } from "@/features/storyboard-view";
import { Generate } from "@/features/storyboard-view/utils";
import { getProjectByNameAction } from "@/entities/projects";
import { getStoryboardAction } from "@/entities/storyboard";
import { isGuestAtom } from "@/entities/viewer";
import exportIcon from "@/assets/shared/export_black.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { ViewTypeTab } from "@/shared/ui";

import { useBehaviour } from "../model";
import { RegenerateImagesModal } from "../ui";

import "./Storyboard.scss";

export const Storyboard: FC = () => {
	const [isOpenExport, toggleOpenExport] = useOpenModal();
	const [isOpenRegenerate, toggleRegenerate] = useOpenModal();

	const [storyboardList] = useAtom(getStoryboardAction.dataAtom);
	const [getStatuses] = useAtom(getStoryboardAction.statusesAtom);
	const [isGuest] = useAtom(isGuestAtom);
	const [project] = useAtom(getProjectByNameAction.dataAtom);
	const getProject = useAction(getProjectByNameAction);

	const {
		onGenerateImages,
		tableView,
		targetRef,
		id,
		setTableView,
		toPDF,
		isSaving
	} = useBehaviour();

	const onRegenerate = () => {
		toggleRegenerate(true);
	};

	const shots: Generate[] = storyboardList.map((scene) => scene.shots.map((shot) => (
		{ shotId: shot.id, isRegenerate: !!shot.image, sceneId: shot.id })
	)).flat();

	const disabled = getStatuses.isPending;
	const isRegenerate = !!storyboardList.map((scene) => scene.shots.filter((shot) => !!shot.image)).flat().length;

	useEffect(() => {
		if (id) {
			getProject(id);
		}
	}, []);

	return (
		<Flex vertical className="storyboard full-height">
			<Flex vertical className="gap-4" style={{ paddingBottom: 16 }}>
				<Flex justify="space-between">
					<Flex justify="center" align="end" className="gap-xxs">
						{/* <StoryboardStep fill="#0C0C0D" className="storyboard__img" /> */}
						<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>{project?.projectName}</Typography.Text>
					</Flex>
					<Flex justify="center" align="center" className="gap-xs">
						{!isGuest && <Button disabled={disabled} type="primary" onClick={isRegenerate ? onRegenerate : onGenerateImages(shots)}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								Generate images
							</Typography.Text>
						</Button>}
						<ViewTypeTab border disabled={disabled} tabView={tableView} changeView={setTableView} />
						{/* <Button onClick={() => toggleOpenSettings(true)} className="flex-ctr-ctr" icon={<img src={settnigs} alt="Settings" />}> */}
						{/*	<Typography.Text className={TEXT_CLASSNAMES.XsRegular}> */}
						{/*		View Settings */}
						{/*	</Typography.Text> */}
						{/* </Button> */}
						{!isGuest && <Button
							className="flex-ctr-ctr"
							icon={<img src={exportIcon} alt="Export" />}
							onClick={() => toggleOpenExport(true)}
							disabled={disabled}
						>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								Export
							</Typography.Text>
						</Button>}
						<ExportStoryboardModal
							toggleModal={toggleOpenExport}
							isOpen={isOpenExport}
							isPending={disabled}
							toPDF={toPDF}
							isSaving={isSaving}
						/>
					</Flex>
				</Flex>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Storyboard displays a complete overview of your project. <br/>
					Each scene is meticulously detailed, allowing you to visualize and adjust all aspects of your film’s narrative and execution
				</Typography.Text>
			</Flex>
			<Flex style={{ position: "absolute", zIndex: -10, opacity: 0, height: 0, overflow: "hidden", top: 0, right: 0 }}>
				<RenderTable targetRef={targetRef} />
			</Flex>
			<Flex className="storyboard__content full-height">
				<StoryboardView />
			</Flex>
			<RegenerateImagesModal
				shots={shots}
				toggleModal={toggleRegenerate}
				isOpen={isOpenRegenerate}
				onGenerateImages={onGenerateImages}
			/>
		</Flex>
	);
};
