import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { editingShotAtom } from "@/features/highlight-script";
import { backAction, descriptionStatusAtom, forwardAction } from "@/entities/script";
import { isGuestAtom } from "@/entities/viewer";
import redo from "@/assets/shared/redo.svg";
import undo from "@/assets/shared/undo.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./DescriptionBottomActions.scss";

interface IDescriptionBottomActions {
	onNext: () => void;
}

export const DescriptionBottomActions: FC<IDescriptionBottomActions> = ({ onNext }) => {
	const { id } = useParams();
	const [descriptionStatus] = useAtom(descriptionStatusAtom);
	const [editingShot] = useAtom(editingShotAtom);
	const historyBack = useAction(backAction);
	const historyForward = useAction(forwardAction);
	const navigate = useNavigate();
	const [isGuest] = useAtom(isGuestAtom);

	const onSkip = () => {
		navigate(`/storyboard/${id}/moodboard`);
	};

	const undoAction = () => {
		if (!editingShot && id) {
			historyBack(id);
		}
	};

	const redoAction = () => {
		if (!editingShot && id) {
			historyForward(id);
		}
	};

	return (
		<Flex align="center" justify="end" className="description-bottom-actions">
			{descriptionStatus === "full" && (
				<>
					<Flex className="gap-xxs description-bottom-history-buttons">
						{!isGuest && (
							<>
								<Button className="description-bottom-actions__history-button" onClick={undoAction}>
									<img src={undo} alt="undo" />
									<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Undo</Typography.Text>
								</Button>
								<Button className="description-bottom-actions__history-button" onClick={redoAction} >
									<img src={redo} alt="redo" />
									<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Redo</Typography.Text>
								</Button>
							</>
						)}
					</Flex>
					{!isGuest && <Button className="description-bottom-actions__button" onClick={onNext} type="primary">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Next</Typography.Text>
					</Button>}
				</>
			)}
			{descriptionStatus === "empty" && !isGuest && (
				<Button type="primary" onClick={onSkip}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Skip step</Typography.Text>
				</Button>
			)}
		</Flex>
	);
};
