import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import { updateShotCameraMovementActions } from "@/features/shot-details/model";
import { isGuestAtom } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

type Props = {
	cameraMovement?: string;
}

export const ShotCameraMovementEdit: FC<Props> = ({ cameraMovement }) => {
	const [currentCameraMovement, setCurrentCameraMovement] = useState(cameraMovement);
	const cameraMovementUpdate = useAction(updateShotCameraMovementActions);
	const [isGuest] = useAtom(isGuestAtom);

	return (
		<>
			<div className="shot-details-info__divider"/>
			<Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
				<Flex vertical className="gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Camera
						Movement</Typography.Text>
					<Typography.Paragraph
						className={TEXT_CLASSNAMES.XsRegular}
						editable={!isGuest && {
							onChange: (value) => {
								setCurrentCameraMovement(value);
								cameraMovementUpdate(value);
							}
						}}
					>
						{currentCameraMovement ?? "-"}
					</Typography.Paragraph>
				</Flex>
			</Flex>
		</>
	);
};
