import { Collapse, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { IOpenModal } from "@/shared/hooks";

import { ShareProps } from "../types.ts";
import { ExpandIcon } from "./ExpandIcon.tsx";
import { InviteWithEmail } from "./InviteWithEmail.tsx";
import { Label } from "./Label.tsx";
import { PagesToggles } from "./PagesToggle.tsx";

import "./ShareProjectModal.scss";

type Props = IOpenModal & ShareProps;

export const ShareProjectModal: FC<Props> = ({ isOpen, toggleModal, onShare }) => {
	return (
		<Modal footer={[]} open={isOpen} onCancel={() => toggleModal(false)}>
			<Flex vertical className="gap-m">
				<Flex vertical className="gap-xxs">
					<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>
						Share project
					</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
						You can invite anyone to the project and they will have access to view your project. You can remove guest from the project at any time.
					</Typography.Text>
				</Flex>
				<Flex vertical className="gap-xxs">
					<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Share pages</Typography.Text>
					<Collapse
						expandIconPosition="end"
						className="collapse-share"
						expandIcon={() => <ExpandIcon />}
						size="large"
						items={[{
							key: "1",
							label: <Label />,
							children: <PagesToggles />
						}]}
					/>
				</Flex>
				<InviteWithEmail onShare={onShare} />
			</Flex>
		</Modal>
	);
};
