import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useControls } from "react-zoom-pan-pinch";

import { initShotsVersionsAction } from "@/features/shot-versions";
import { callErrorAction } from "@/entities/notification";
import { getShotAction, shotEditingImageUploadLoadingAtom, shotsOrderAtom, updateShotAction } from "@/entities/shot-edit";
import { regenerateImageAction } from "@/entities/storyboard";
import { isGuestAtom } from "@/entities/viewer";
import { setCustomImageResource } from "@/shared/api/storyboard";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { downloadImage } from "@/shared/methods";
import download from "@/assets/shot-selection/download.svg";
// import left from "@/assets/shot-selection/left.svg";
import minus from "@/assets/shot-selection/minus.svg";
import plus from "@/assets/shot-selection/plus.svg";
import upload from "@/assets/shot-selection/upload.svg";
// import right from "@/assets/shot-selection/right.svg";
import "./Actions.scss";

export const Actions: FC = () => {
	const initShotsVersions = useAction(initShotsVersionsAction);
	const [_, setShotEditingImageUploadLoading] = useAtom(shotEditingImageUploadLoadingAtom);
	const updateShot = useAction(updateShotAction);
	const [isGuest] = useAtom(isGuestAtom);

	const [fileLoading, setFileLoading] = useState(false);
	const catchError = useAction(callErrorAction);
	const [shots] = useAtom(shotsOrderAtom);
	const [shot] = useAtom(getShotAction.dataAtom);
	const [regenerateImageStatus] = useAtom(regenerateImageAction.statusesAtom);
	const { zoomIn, zoomOut } = useControls();
	const { shotId, id } = useParams();

	const onDownload = async () => {
		const currentShot = shots.find((shot) => shot.id === shotId);

		if (shot?.image_url && currentShot) {
			await downloadImage(shot.image_url, `${`Scene ${currentShot.sceneIdx + 1}. Shot ${currentShot.shotIdx + 1}`}`);
		}
	};

	const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		try {
			setFileLoading(true);
			setShotEditingImageUploadLoading(true);

			const formData = new FormData();
			const file = event.target?.files?.[0];
			formData.append("file", file ?? "");
			const { data } = await setCustomImageResource(id!, shotId!, formData);
			initShotsVersions(shotId!);
			updateShot(data);
		} catch (e) {
			catchError(e);
		} finally {
			setFileLoading(false);
			setShotEditingImageUploadLoading(false);
		}
	};

	return (
		<Flex justify="space-between" className="flex-0 full-width">
			<Flex className="gap-s">
				{!isGuest && (
					<>
						<Flex className="gap-4 actions-size">
							<Button
								className="actions-size__button"
								icon={<img src={plus} alt="plus" />}
								onClick={() => zoomIn()}
							/>
							<Button
								className="actions-size__button"
								icon={<img src={minus} alt="minus" />}
								onClick={() => zoomOut()}
							/>
						</Flex>
						<Button
							disabled={regenerateImageStatus.isPending}
							onClick={onDownload}
							className="flex-ctr-ctr actions-size"
							icon={<img src={download} alt="download" />}
						>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>Download</Typography.Text>
						</Button>
						<Button
							disabled={regenerateImageStatus.isPending}
							className="flex-ctr-ctr actions-size"
							icon={<img src={upload} alt="upload" />}
							style={{ position: "relative" }}
							loading={fileLoading}
						>
							<input onChange={handleUpload} accept="image/*" type="file" multiple={false} style={{ position: "absolute", opacity: 0, top: 0, left: 0, width: "100%", height: "100%" }}/>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>Upload image</Typography.Text>
						</Button>
					</>
				)}

				{/* <Flex className="gap-4 actions-size"> */}
				{/*	<Button className="actions-size__button" icon={<img src={left} alt="left" />} /> */}
				{/*	<Button className="actions-size__button" icon={<img src={right} alt="right" />} /> */}
				{/* </Flex> */}
			</Flex>
			{/* <Flex className="gap-xxs"> */}
			{/*	<Button disabled onClick={onBack}>Cancel</Button> */}
			{/*	<Button disabled type="primary">Save</Button> */}
			{/* </Flex> */}
		</Flex>
	);
};
