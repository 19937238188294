import { useAtom } from "@reatom/npm-react";
import { viewerPermissionAtom } from "@/entities/viewer";
import { PermissionPage, PermissionShare } from "@/shared/types/share.ts";

type Params = {
	page: PermissionPage;
	group: PermissionShare;
}

export const usePermission = (params: Params) => {
	const [permissions] = useAtom(viewerPermissionAtom);

	const permission = permissions?.find((el) => el.page_title === params.page && el.permission_group_title === params.group);

	return !!permission;
};
