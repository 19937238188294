import { action, atom } from "@reatom/core";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { AxiosError } from "axios";
import { callErrorAction } from "@/entities/notification";
import {
	CREDENTIAL__REGISTER_KEY,
	CREDENTIAL_KEY,
	PLAN_EMAIL,
	USER_PERMISSION_KEY,
	USER_SUBSCRIPTION_KEY
} from "@/entities/viewer";
import { deleteUserResource, getUserPermissionResource, Permission, TRegisterKey, UserType } from "@/shared/api/auth";
import {
	getCustomerSubscriptionResource,
	UserSubscription
} from "@/shared/api/payment";

export const viewerAtom = atom<TRegisterKey | null>(null, "viewerAtom").pipe(withLocalStorage(CREDENTIAL_KEY));
export const viewerRegisterAtom = atom<TRegisterKey | null>(null, "viewerRegisterAtom").pipe(withLocalStorage(CREDENTIAL__REGISTER_KEY));
export const viewerSubscriptionAtom = atom<UserSubscription | null>(null, "viewerSubscriptionAtom").pipe(withLocalStorage(USER_SUBSCRIPTION_KEY));
export const viewerPermissionAtom = atom<Permission[] | null>(null, "viewerPermissionAtom").pipe(withLocalStorage(USER_PERMISSION_KEY));
export const isGuestAtom = atom<boolean>((ctx) => {
	const permission = ctx.spy(viewerPermissionAtom);

	if (Array.isArray(permission)) {
		return !!permission.length;
	}

	return false;
});

export const getTrialEndAction = action((ctx) => {
	const subscription = ctx.get(viewerSubscriptionAtom);

	if (subscription?.trial_status === "on_trial") {
		const a = new Date();
		const b = new Date((subscription?.trial_end ?? 0) * 1000);
		const _MS_PER_DAY = 1000 * 60 * 60 * 24;
		// Discard the time and time-zone information.
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

		const days = Math.floor((utc2 - utc1) / _MS_PER_DAY);
		const isEnd = days < 0;

		return {
			trialStatus: subscription?.trial_status,
			days,
			isEnd,
			message: subscription?.message ?? ""
		};
	}

	return {
		trialStatus: subscription?.trial_status,
		days: -1,
		isEnd: true,
		message: subscription?.message ?? ""
	};
});

export const sessionLoginAction = action((ctx, credential: TRegisterKey) => {
	viewerAtom(ctx, credential);
}, "session login");

export const sessionPreLoginAction = action((ctx, credential: TRegisterKey) => {
	viewerRegisterAtom(ctx, credential);
}, "session pre login");

export const logoutAction = action(() => {
	localStorage.removeItem(PLAN_EMAIL);
	localStorage.removeItem(CREDENTIAL__REGISTER_KEY);
	localStorage.removeItem(CREDENTIAL_KEY);
	localStorage.removeItem(USER_SUBSCRIPTION_KEY);
	localStorage.removeItem(USER_PERMISSION_KEY);
	window.location.href = "/login";
});

export const deleteAccountAction = action(async (ctx) => {
	try {
		await deleteUserResource();
		logoutAction(ctx);
	} catch (e) {
		callErrorAction(ctx, e as AxiosError);
	}
});

export const subscriptionAction = action(async (ctx, token: string): Promise<UserSubscription> => {
	const { data: subscription } = await getCustomerSubscriptionResource(token);

	viewerSubscriptionAtom(ctx, subscription);

	return subscription;
});

export const permissionAction = action(async (ctx, token): Promise<UserType> => {
	const { data: permission } = await getUserPermissionResource(token);

	if (Array.isArray(permission)) {
		viewerPermissionAtom(ctx, permission);
		return permission.length ? "guest" : "customer";
	}

	return "customer";
});
