import { PermissionShare } from "@/shared/types/share.ts";

type Option = {
	label: string;
	value: PermissionShare;
}

export const SHARE_OPTIONS: Option[] = [
	{
		label: "can view",
		value: "view"
	},
	{
		label: "can comment",
		value: "comment"
	}
];
