import { useAtom } from "@reatom/npm-react";
import { FC, PropsWithChildren } from "react";

import { usePermission } from "@/shared/hooks";
import { RightSidebar } from "@/shared/ui";

import { TShotSidebarView } from "../../lib";
import { shotSidebarViewAtom } from "../../model";

export const RightSidebarShot: FC<PropsWithChildren> = ({ children }) => {
	const [sidebarView, changeView] = useAtom(shotSidebarViewAtom);
	const canComment = usePermission({ page: "moodboard", group: "comment" });

	const sidebarCustomerList: { title: string, value: TShotSidebarView }[] = [
		{
			value: "details",
			title: "Details"
		},
		{
			value: "edit",
			title: "Edit"
		},
		{
			value: "versions",
			title: "Versions"
		},
		{
			value: "comments",
			title: "Comments"
		}
	];

	const sidebarViewList = canComment ? sidebarCustomerList : sidebarCustomerList.slice(0, -1);

	return (
		<RightSidebar
			sidebarViewList={sidebarViewList}
			activeView={sidebarView}
			changeView={changeView}
		>
			{children}
		</RightSidebar>
	);
};
