import { AxiosResponse } from "axios";
import { api } from "@/shared/api";

import {
	GeneratedShotsOrderResult, GenerateImageResult,
	GenerateShotParams,
	GenerateShotResult,
	GetShotParams,
	GetShotResult,
	RegenerateImageParams,
	ShotImage, ShotVersion,
	TStoryboardResponse,
	UpdateSceneParams,
	UpdateShotParams
} from "./types";
import { TResponse } from "@/vite-env";

export const getStoryboardResource = (projectId: string, controller: AbortController): Promise<AxiosResponse<TStoryboardResponse>> =>
	api.get(`/storyboards/${projectId}/full/`, { signal: controller.signal });

export const getStoryboardShotResource = (params: GetShotParams, controller: AbortController): TResponse<GetShotResult> =>
	api.get(`/storyboards/${params.projectId}/shots/${params.shotId}`, { signal: controller.signal });

function validURL (str: string) {
	const pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
		"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
		"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
		"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
		"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
		"(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
	return !!pattern.test(str);
}

export const generateStoryboardShotResource = async (params: GenerateShotParams): TResponse<GenerateImageResult> => {
	const res = await api.post<GenerateShotResult>(`/storyboards/${params.projectId}/shots/${params.shotId}/image`);

	if (validURL(res.data.image_url_compressed)) {
		return res;
	}

	// eslint-disable-next-line no-throw-literal
	throw ("Error generating image");
}
;

export const regenerateImageResource = async (params: RegenerateImageParams): TResponse<GenerateImageResult> => {
	await api.patch(`/storyboards/${params.projectId}/shots/${params.shotId}`);
	const res = await api.get<GenerateShotResult>(`/storyboards/${params.projectId}/shots/${params.shotId}`);

	if (validURL(res.data.image_url_compressed)) {
		return res;
	}

	// eslint-disable-next-line no-throw-literal
	throw ("Error generating image");
};

export const getProjectsStoryboardImagesResource = (name: string): Promise<AxiosResponse<{ [id: string]: ShotImage }>> =>
	api.get(`/storyboards/${name}/scenes/shots/images`);

export const updateStoryboardShotResource = (projectId: string, params: UpdateShotParams) =>
	api.patch(`/storyboards/${projectId}/scenes/shots/`, params);

export const updateStoryboardSceneResource = (projectId: string, params: UpdateSceneParams) =>
	api.put(`/storyboards/${projectId}/scenes`, params);

export const getGeneratedOrderShotsResource = (projectId: string): TResponse<GeneratedShotsOrderResult> => {
	return api.get(`/storyboards/${projectId}/ordered-shots/`);
};

export const setCustomImageResource = (projectId: string, shotId: string, image: FormData) =>
	api.post(`/storyboards/shot/${projectId}/${shotId}/custom_main_image`, image, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});

export const changeMainImageResource = (shotId: string, imageId: number) =>
	api.patch(`/storyboards/shot/${shotId}/main_image`, {
		image_id: imageId
	});

export const getShotImagesVersionsResource = (shotId: string): TResponse<ShotVersion[]> =>
	api.get(`/storyboards/shot/${shotId}/images`);
