import { Flex, Form, Input, message, Typography } from "antd";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { Header } from "@/widgets/header";
import { UseCaseFooter } from "@/entities/use-cases";
import { COLOR_NAMES } from "@/shared/const/color-names.ts";
import { useToTop } from "@/shared/hooks";
import { trackGoogleAnalyticsEvent } from "@/shared/methods";
import { LandingContent, PageContainer, PageHeader, TrialButton } from "@/shared/ui";

import "./Contacts.scss";

interface IFeedbackForm {
	email: string;
	message: string;
	name: string;
}

export const Contacts: FC = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [_isLoading, setIsLoading] = useState(false);
	const [isEmailSendError, setIsEmailSendError] = useState(false);
	const [isReCAPTCHAShown, setIsReCAPTCHAShown] = useState(false);
	const [isReCAPTCHAVerified, setIsReCAPTCHAVerified] = useState(false);
	const [isButtonDisable, setIsButtonDisable] = useState(false);

	useToTop();

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid!"
		}
	};
	/* eslint-enable no-template-curly-in-string */

	const onFinish = async (values: IFeedbackForm) => {
		if (!isReCAPTCHAVerified) {
			setIsReCAPTCHAShown(true);
			setIsButtonDisable(true);
			return;
		}

		try {
			setIsLoading(true);

			const data = {
				service_id: "service_fftdtow",
				template_id: "template_lae8dge",
				user_id: "AQK3iUSsogwlK2dMZ",
				template_params: {
					email: values.email,
					message: values.message
				}
			};

			TagManager.dataLayer({
				dataLayer: {
					event: "contact_us",
					contactUserName: values.name,
					contactUserMessage: values.message
				}
			});
			trackGoogleAnalyticsEvent("white_list", "contacts", "submit", {
				...values,
				email: values.email.replace("@", "_")
			});

			await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
				...data
			}, {
				headers: {
					"Content-Type": "application/json"
				}
			});

			messageApi.open({
				type: "success",
				content: "Your application has been successfully accepted"
			});
			setIsEmailSendError(false);
			setIsReCAPTCHAVerified(false);
			setIsReCAPTCHAShown(false);
		} catch (e) {
			setIsEmailSendError(true);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	const handleReCAPTCHAChange = (value: string | null) => {
		setIsReCAPTCHAVerified(!!value);
		setIsButtonDisable(!value);
	};

	return (
		<Flex align="center" justify="center" vertical className="contacts">
			{contextHolder}
			<div style={{ background: COLOR_NAMES.BackgroundWhite, width: "100%" }}>
				<LandingContent>
					<Header />
				</LandingContent>
			</div>
			<PageHeader headerText="Let’s get" italicHeaderText="in TOUCH" description="We're eager to hear from you! Share your inquiries, feedback, or requests
with us by filling out the form below. Our team will respond promptly to assist you."/>
			<PageContainer>
				<Form onFinish={onFinish} validateMessages={validateMessages} layout="vertical" className="contacts__content">
					<Flex vertical className="gap-s text-center">
						<Flex vertical gap="middle">
							<Form.Item id="name" name="name" noStyle rules={[{ required: true }]}>
								<Input placeholder="Name"/>
							</Form.Item>
							<Form.Item id="email" name="email" noStyle rules={[{ type: "email", required: true }]}>
								<Input placeholder="Email"/>
							</Form.Item>
							<Form.Item id="message" name="message" noStyle rules={[{ required: true }]}>
								<Input.TextArea placeholder="Message" rows={3}/>
							</Form.Item>
							{isReCAPTCHAShown && (
								<Form.Item>
									<ReCAPTCHA sitekey={`${import.meta.env.VITE_GOOGLE_RECAPTCHA_SITETOKEN}`} onChange={handleReCAPTCHAChange} />
								</Form.Item>
							)}
						</Flex>
						{isEmailSendError && <Typography.Text className="error">Oops something went wrong</Typography.Text>}
						<Form.Item>
							<TrialButton disabled={isButtonDisable} onClick={() => {}} type="submit" text="Send" fullWidth />
						</Form.Item>
					</Flex>
				</Form>
			</PageContainer>
			<div className="full-width">
				<UseCaseFooter/>
			</div>
		</Flex>
	);
};
