import { Flex } from "antd";
import { FC, PropsWithChildren } from "react";

import "./CommentSidebar.scss";

export const CommentSidebar: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Flex vertical className="comment-sidebar gap-s">
			{children}
		</Flex>
	);
};
