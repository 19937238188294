import { useGoogleLogin } from "@react-oauth/google";
import { useAction } from "@reatom/npm-react";
import { Button, Checkbox, Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { permissionAction, sessionLoginAction, sessionPreLoginAction, subscriptionAction } from "@/entities/viewer";
import googleIcon from "@/assets/shared/google_icon.svg";
import { axiosErrorHandler } from "@/shared/api";
import { createUserGoogleResource, loginResource, loginUserGoogleResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { DisableSubmitButton } from "@/shared/ui";
import { validateEmail } from "@/shared/validation";
import { IMessageError } from "@/vite-env";

type FieldType = {
	email: string;
	password: string;
	remember: boolean;
};

type Props = {
	handleRedirectToRecover: () => void;
	isFromInstagram: boolean;
}

export const LoginForm: FC<Props> = ({ handleRedirectToRecover, isFromInstagram }) => {
	const navigate = useNavigate();
	const { isLaptopS } = useMediaQueries();
	const [form] = Form.useForm();
	const email = Form.useWatch("email", { form, preserve: true });
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const sessionLogin = useAction(sessionLoginAction);
	const preSessionLogin = useAction(sessionPreLoginAction);
	const getSubscription = useAction(subscriptionAction);
	const getPermission = useAction(permissionAction);

	const onLogin = async (data: FieldType) => {
		try {
			setLoading(true);

			const { data: credential } = await loginResource({
				email: data.email,
				password: data.password
			});

			TagManager.dataLayer({
				dataLayer: {
					event: "login",
					loginMethod: "email"
				}
			});
			const userType = await getPermission(credential.key);

			if (userType === "customer") {
				await getSubscription(credential.key);
			}

			preSessionLogin(credential);
			sessionLogin(credential);
			navigate("/main/projects");
		} catch (err) {
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				if (error.response?.status === 403) {
					if (error.response.data.message === "Access denied") {
						const { data: credential } = await loginResource({
							email: data.email,
							password: data.password
						});
						console.log("credential :>> ", credential);
						preSessionLogin(credential);

						navigate("/plan");
					} else {
						setError("Incorrect email or password");
					}
				} else {
					setError("Oops, something went wrong");
				}
			} else {
				setError("Oops, something went wrong");
			}
		} finally {
			setLoading(false);
		}
	};

	const onGoogleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			try {
				setLoading(true);
				loginUserGoogleResource(tokenResponse)
					.then(async ({ data: credential }) => {
						TagManager.dataLayer({
							dataLayer: {
								event: "login",
								loginMethod: "google"
							}
						});

						await getSubscription(credential.key);

						sessionLogin(credential);
						navigate("/main/projects");
					})
					.catch(async (err) => {
						const status = err?.response?.status;

						if (status === 404) {
							const { data: credential } = await createUserGoogleResource(tokenResponse);
							TagManager.dataLayer({
								dataLayer: {
									event: "sign_up",
									signupMethod: "google"
								}
							});
							preSessionLogin(credential);
							navigate("/plan");
						} else if (status === 409) {
							setError("Incorrect email or password");
						} else {
							setError("Oops, something went wrong");
						}
					});
			} catch (err) {
				const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

				if (type === "axios-error") {
					if (error?.status === 409) {
						setError("Incorrect email or password");
					} else {
						setError("Oops, something went wrong");
					}
				}
			} finally {
				setLoading(false);
			}
		},
		onError: (data) => console.log("==========>data", data)
	});

	return (
		<Form
			name="basic"
			initialValues={{ remember: true }}
			onFinish={onLogin}
			autoComplete="off"
			className="login-form"
			form={form}
			validateTrigger={["onSubmit"]}
		>
			<Flex vertical className="gap-xs">
				<Form.Item<FieldType>
					name="email"
					rules={[
						{ required: true, message: "Please input your email" },
						{ type: "email", message: "Please input your valid email" }
					]}
					hasFeedback
					validateStatus={validateEmail(email)}
				>
					<Input
						onChange={(event) => form.setFieldValue("email", event.target.value)}
						className="login-form__input"
						placeholder="Email"
					/>
				</Form.Item>
				<Form.Item<FieldType>
					name="password"
					style={{
						maxWidth: 380
					}}
					rules={[
						{ required: true, message: "Please input your password" }
					]}
				>
					<Input.Password
						className="login-form__input"
						placeholder="Password"
						onChange={(event) => form.setFieldValue("password", event.target.value)}
					/>
				</Form.Item>
			</Flex>
			<Flex justify="space-between" align="center" className="full-width login-form__actions">
				<Form.Item<FieldType>
					className="login-form__checkbox"
					name="remember"
					valuePropName="checked"
				>
					<Checkbox>
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
							[TEXT_CLASSNAMES.XxsRegular]: isLaptopS
						})}>
							Remember me
						</Typography.Text>
					</Checkbox>
				</Form.Item>
				<Typography.Text
					onClick={handleRedirectToRecover}
					className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, "pointer", {
						[TEXT_CLASSNAMES.XxsRegular]: isLaptopS
					})}
				>
					Forgot password?
				</Typography.Text>
			</Flex>
			<Flex vertical className="login-form__buttons gap-xs">
				<DisableSubmitButton loading={loading} errorMessage={error} type="primary" form={form}>
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>
						Sign in
					</Typography.Text>
				</DisableSubmitButton>
				{!isFromInstagram && (
					<Button
						htmlType="button"
						className="login-form__buttons__google"
						icon={<img src={googleIcon} alt="google icon" />}
						onClick={() => onGoogleLogin()}
						loading={loading}
					>
						<Typography.Text className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.XsRegular)}>
							Login with Google
						</Typography.Text>
					</Button>
				)}
			</Flex>
		</Form>
	);
};
