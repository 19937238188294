import { Button, Flex, Form, Input, Select, Typography } from "antd";
import { useRef, useState } from "react";
import close from "@/assets/shared/close_square.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import {
	InviteNewMemberField as InviteNewMemberFieldProps,
	SelectProps,
	InviteProps,
	InviteMemberFieldType
} from "./types.ts";

type Props = InviteNewMemberFieldProps & SelectProps & InviteProps;

export function InviteEmailField ({ onDelete, id, placeholder, options, onFinish }: Props) {
	const [hoverDelete, setHoverDelete] = useState(false);
	const timeout = useRef<null | ReturnType<typeof setTimeout>>(null);

	const onMouseEnter = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}

		setHoverDelete(true);
	};

	const onMouseLeave = () => {
		timeout.current = setTimeout(() => {
			setHoverDelete(false);
		}, 100);
	};

	return (
		<Form
			onMouseOver={onMouseEnter}
			onMouseOut={onMouseLeave}
			onFinish={onFinish}
			className="inviter-member__form"
		>
			{hoverDelete && <img onClick={() => onDelete(id)} className="inviter-member__delete" src={close} alt="delete" />}
			<Flex key="unique" className="gap-xxs" justify="space-between">
				<Form.Item<InviteMemberFieldType>
					name="email"
					rules={[{ required: true, message: "Missing email" }]}
					style={{ width: "100%" }}
				>
					<Input placeholder="email@address.com" />
				</Form.Item>
				<Form.Item<InviteMemberFieldType>
					name="select"
					rules={[{ required: true, message: "Missing field" }]}
				>
					<Select
						placeholder={placeholder}
						style={{ height: 44, width: 174 }}
						options={options}
					/>
				</Form.Item>
				<Button htmlType="submit" type="primary">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Invite</Typography.Text>
				</Button>
			</Flex>
		</Form>
	);
}
