import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography, notification } from "antd";
import { FC, useEffect } from "react";

import { AddNewProject } from "@/features/add-new-project";
import { EmptyProject } from "@/features/empty-project";
import { FullProject } from "@/features/full-project";
import { initProjectsAction, projectsListAtom, projectsLoadingAtom } from "@/entities/projects";
import { isGuestAtom, viewerSubscriptionAtom } from "@/entities/viewer";

import plus from "@/assets/shared/plus_square.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";
import { Spinner } from "@/shared/ui";
import { NotificationError } from "@/assets/components/NotificationError";

import "./Projects.scss";

export const Projects: FC = () => {
	const getAllProjects = useAction(initProjectsAction);
	const [loading] = useAtom(projectsLoadingAtom);
	const [projects] = useAtom(projectsListAtom);
	const [isOpen, toggleModal] = useOpenModal();
	const [viewerSubscription] = useAtom(viewerSubscriptionAtom);
	const [api, contextHolder] = notification.useNotification();
	const [isGuest] = useAtom(isGuestAtom);

	const isProject = !!projects.length;

	useEffect(() => {
		getAllProjects();
	}, []);

	const createNewProject = () => {
		if (!viewerSubscription) return;

		if (projects.length >= viewerSubscription.num_projects) {
			api.error({
				message: <Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>Exceeded number of projects created</Typography.Text>,
				description: <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>To continue creating new projects, you will need to upgrade your plan or archive/delete an existing project.</Typography.Text>,
				placement: "top",
				closeIcon: null,
				icon: <NotificationError />,
				style: { width: 460 }
			});
		} else {
			toggleModal(true);
		}
	};

	return (
		<>
			{contextHolder}
			<Flex vertical className="projects gap-s full-height">
				<Flex align="center" justify="space-between" className="full-width">
					<Flex align="center" className="gap-4">
						{/* <ProjectsSVG className="projects__img" fill={COLOR_NAMES.TextPrimary} /> */}
						<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>
							Your projects
						</Typography.Text>
					</Flex>
					{isProject && !isGuest && (
						<Button
							onClick={createNewProject}
							className="projects__button"
							icon={<img src={plus} alt="plus" />}
							type="primary"
						>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>New project</Typography.Text>
						</Button>
					)}
				</Flex>
				{loading
					? (
						<Spinner isOverlay />
					)
					: (
						<>
							{isProject
								? <FullProject />
								: <EmptyProject />}
						</>
					)}
				<AddNewProject isOpen={isOpen} toggleModal={toggleModal} />
			</Flex>
		</>

	);
};
