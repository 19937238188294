import { useAction } from "@reatom/npm-react";
import { Button, Divider, Flex, Modal, Typography, message } from "antd";
import cn from "classnames";
import { FC, useState } from "react";

import { inviteTeamMemberAction } from "@/features/team-settings";
import { TEAM_MEMBER_MAP } from "@/features/team-settings/lib";
import arrowSend from "@/assets/shared/send_arrow.svg";
import { TeamMemberPosition } from "@/shared/api/teams";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { InviteEmail, InviteMemberFieldType } from "@/shared/ui";
import copyLink from "@/assets/settings/team/copy-link.svg";

import "./InviteNewMember.scss";

export const InviteNewMember: FC = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const inviteTeamMember = useAction(inviteTeamMemberAction);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const onCopy = () => {
		messageApi.open({
			type: "success",
			content: "Link successfully copied!"
		});
	};

	const onFinish = (values: InviteMemberFieldType) => {
		const position = values.select as TeamMemberPosition;
		inviteTeamMember({
			email: values.email,
			position,
			role: "collaborator"
		});
	};

	const options = Object.entries(TEAM_MEMBER_MAP).map(([key, data]) => ({
		value: key,
		label: data.title
	}));

	return (
		<>
			{contextHolder}
			<Button
				onClick={showModal}
				className="flex-ctr-ctr gap-xxs"
				type="primary"
			>
				<img src={arrowSend} alt="send" />
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Invite new member
				</Typography.Text>
			</Button>
			<Modal
				open={isModalOpen}
				onCancel={handleCancel}
				footer={[]}
				width={690}
				className="inviter-member"
			>
				<InviteEmail title="Invite people to your team" options={options} placeholder="Position" onFinish={onFinish} />
				<Divider />
				<Flex vertical className="gap-xs">
					<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Invite with Link</Typography.Text>
					<Flex className="gap-xxs">
						<Flex
							align="center"
							className="full-width inviter-member__link"
						>
							<Typography.Text className={cn("inviter-member__text", TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)}>
								To be, or not to be, that is the question: Whether it is nobler in the mind to suffer. The slings and arrows of outrageous fortune Or to take arms against a sea of troubles, And by opposing end them? To die: to sleep; No more; and by a sleep to s
							</Typography.Text>
						</Flex>
						<Button onClick={onCopy} className="flex-ctr-ctr gap-xxs">
							<img src={copyLink} alt="copy" />
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Copy Link</Typography.Text>
						</Button>
					</Flex>
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Anyone with access to this link can join your team</Typography.Text>
				</Flex>
			</Modal>
		</>
	);
};
