import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Input, InputRef, Tag, Typography } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { updateShotPropsActions } from "@/features/shot-details/model";
import { isGuestAtom } from "@/entities/viewer";
import plus from "@/assets/shared/plus_square.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { ActionRestrictor } from "@/shared/ui";
import close from "@/assets/shot-selection/close.svg";

type Props = {
	props?: string;
}

export const ShotPropsEdit: FC<Props> = ({ props }) => {
	const [inputVisible, setInputVisible] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const updateShotProps = useAction(updateShotPropsActions);
	const propsArray = (props ?? "")?.split(",")?.filter((el) => el);
	const [currentProps, setCurrentProps] = useState(propsArray);
	const inputRef = useRef<InputRef>(null);
	const [isGuest] = useAtom(isGuestAtom);

	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = () => {
		if (inputValue && currentProps.indexOf(inputValue) === -1) {
			const newProps = [...currentProps, inputValue];
			const newPropsString = newProps?.join(",") ?? "";

			setCurrentProps(newProps);
			updateShotProps(newPropsString);
		}
		setInputVisible(false);
		setInputValue("");
	};

	const onDelete = (value: string) => {
		const newProps = currentProps?.filter((el) => el !== value);
		const newPropsString = newProps?.join(",") ?? "";

		setCurrentProps(newProps);
		updateShotProps(newPropsString);
	};

	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	return (
		<>
			<div className="shot-details-info__divider"/>
			<Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
				<Flex vertical className="gap-xxs">
					<Flex align="center" justify="space-between">
						<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Props</Typography.Text>
						{inputVisible
							? (
								<Input
									ref={inputRef}
									type="text"
									size="small"
									style={{ width: 78 }}
									value={inputValue}
									onChange={handleInputChange}
									onBlur={handleInputConfirm}
									onPressEnter={handleInputConfirm}
								/>
							)
							: (
								<ActionRestrictor show={!isGuest}>
									<Flex align="center" className="gap-4 pointer" onClick={showInput}>
										<img width={20} height={20} src={plus} alt="plus"/>
										<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
											Add
										</Typography.Text>
									</Flex>
								</ActionRestrictor>
							)}
					</Flex>
					<Flex wrap="wrap" className="gap-xxs">
						{currentProps.map((tag) => (
							<Tag className="shot-details-info__tag flex-ctr-ctr gap-4" key={tag}>
								{tag}
								<ActionRestrictor show={!isGuest}>
									<img onClick={() => onDelete(tag)} className="pointer" src={close} alt="close"/>
								</ActionRestrictor>
							</Tag>
						))}
					</Flex>
					{/* <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{props ?? "-"}</Typography.Text> */}
				</Flex>
			</Flex>
		</>
	);
};
