import { useAtom } from "@reatom/npm-react";
import { FC, PropsWithChildren } from "react";

import { isGuestAtom } from "@/entities/viewer";
import { RightSidebar } from "@/shared/ui";

import { TRightSidebarView } from "../../lib";
import { moodBoardSidebarView } from "../../model";

export const RightSidebarMoodboard: FC<PropsWithChildren> = ({ children }) => {
	const [sidebarView, changeView] = useAtom(moodBoardSidebarView);
	const [isGuest] = useAtom(isGuestAtom);
	const sidebarCustomerList: { title: string, value: TRightSidebarView }[] = [
		{
			value: "inspiration",
			title: "Inspiration board"
		},
		{
			value: "search",
			title: "Search"
		},
		{
			value: "archive",
			title: "Archive"
		}
	];

	const sidebarGuestList: { title: string, value: TRightSidebarView }[] = [
		{
			value: "comments",
			title: "Comments"
		}
	];

	const sidebarViewList = !isGuest ? [...sidebarCustomerList, ...sidebarGuestList] : sidebarGuestList;

	return (
		<RightSidebar<TRightSidebarView>
			changeView={changeView}
			sidebarViewList={sidebarViewList}
			activeView={sidebarView}
		>
			{children}
		</RightSidebar>
	);
};
