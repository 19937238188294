import { useAction } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, PropsWithChildren, useEffect, useState } from "react";

import { subscriptionAction } from "@/entities/viewer";
import { getCredential } from "@/entities/viewer/lib/utils.ts";
import { Spinner } from "@/shared/ui";

export const PermissionWrapper: FC<PropsWithChildren> = ({ children }) => {
	const viewer = getCredential();
	const getSubscription = useAction(subscriptionAction);
	const getPermission = useAction(subscriptionAction);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const isAuth = viewer?.data?.key;

				if (isAuth) {
					await getSubscription(viewer?.data?.key ?? "");
					await getPermission(viewer?.data?.key ?? "");
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};

		init();
	}, []);

	if (loading) {
		return (
			<Flex align="center" justify="center" className="full-width full-height">
				<Spinner isOverlay />
			</Flex>
		);
	}

	return (
		<>
			{children}
		</>
	);
};
