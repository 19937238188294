import { Button, Typography } from "antd";
import cn from "classnames";
import { FC, MouseEvent } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./ImageHoverAction.scss";
import { useAtom } from "@reatom/npm-react";
import { isGuestAtom } from "@/entities/viewer";

type Props = {
	image: string;
	onGenerate: () => void;
	isHoverImage: boolean;
}

export const ImageHoverAction: FC<Props> = ({ image, onGenerate, isHoverImage }) => {
	const [isGuest] = useAtom(isGuestAtom);
	const onModalOpen = (event: MouseEvent<HTMLDivElement>) => {
		event.persist();
		event.stopPropagation();
		onGenerate();
	};

	if (!image && !isGuest) {
		return (
			<Button onClick={onModalOpen} className={cn("image-hover-action flex-ctr-ctr", {
				"image-hover-action__active": isHoverImage
			})}>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					Generate image
				</Typography.Text>
			</Button>
		);
	}

	return null;
};
