import { Flex, Typography } from "antd";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

export const Label: FC = () => (
	<Flex vertical>
		<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>All pages</Typography.Text>
		<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>You can manage access to pages.</Typography.Text>
	</Flex>
);
