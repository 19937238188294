import { useAction, useAtom } from "@reatom/npm-react";
import { useDebounceFn } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { updateSceneTitleAction } from "@/entities/script";
import { isGuestAtom } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./SceneTitle.scss";

type Props = {
	idx: number;
	sceneId: string;
	title: string
}

export const SceneTitle: FC<Props> = ({ idx, sceneId, title }) => {
	const updateScene = useAction(updateSceneTitleAction);
	const { id } = useParams();
	const titleRef = useRef<HTMLSpanElement>(null);
	const [isGuest] = useAtom(isGuestAtom);

	const { run } = useDebounceFn((event: ChangeEvent<HTMLInputElement>) => {
		const { innerText } = event.target;

		if (id) {
			updateScene(id, { sceneId, title: innerText });
		}
	}, { wait: 500 });

	useEffect(() => {
		if (titleRef.current) {
			titleRef.current.innerText = title;
		}
	}, []);

	return (
		<Flex align="center" className="scene-title gap-4">
			<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextPrimary)}>
				Scene {idx + 1}
				&nbsp;
				"
				<span ref={titleRef} className="scene-title__edit" role="textbox" contentEditable={!isGuest} onInput={run} />
				"
			</Typography.Text>
		</Flex>
	);
};
