import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC } from "react";
import { SHARE_OPTIONS } from "@/shared/const/share.ts";
import { InviteEmail, InviteMemberFieldType } from "@/shared/ui";
import { activeSharingPagesAtom } from "../model";
import { ShareProps } from "../types.ts";

export const InviteWithEmail: FC<ShareProps> = ({ onShare }) => {
	const [sharingPages] = useAtom(activeSharingPagesAtom);

	const onFinish = (fields: InviteMemberFieldType) => {
		onShare({
			...fields,
			...sharingPages
		});
	};

	return (
		<Flex vertical className="gap-xxs">
			<InviteEmail title="" options={SHARE_OPTIONS} onFinish={onFinish} placeholder="Permission" />
		</Flex>
	);
};
