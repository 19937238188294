import { useAction } from "@reatom/npm-react";
import { FC } from "react";
import { ShareProjectModal, TPages, shareProjectAction } from "@/entities/projects";
import { ProjectPermission } from "@/shared/api/project";
import { IOpenModal } from "@/shared/hooks";
import { PermissionPage, PermissionShare } from "@/shared/types/share";
import { InviteMemberFieldType } from "@/shared/ui";

interface IShareProjectModal extends IOpenModal {
    name: string
}

export const ShareProject: FC<IShareProjectModal> = ({ isOpen, toggleModal, name }) => {
	const shareProject = useAction(shareProjectAction);

	const onShare = async (params: InviteMemberFieldType & TPages) => {
		const permissions: ProjectPermission[] = [];
		const permissionName = params.select as PermissionShare;

		const addToArray = (page: PermissionPage) => {
			if (permissionName === "view") {
				permissions.push({
					permission_group_name: "view",
					permission_page: page
				});
			}

			if (permissionName === "comment") {
				permissions.push({
					permission_group_name: "view",
					permission_page: page
				});

				permissions.push({
					permission_group_name: "comment",
					permission_page: page
				});
			}
		};

		if (params.storyboards) {
			addToArray("storyboards");
		}

		if (params.script) {
			addToArray("script");
		}

		if (params.characters) {
			addToArray("characters");
		}

		if (params.moodboard) {
			addToArray("moodboard");
		}

		try {
			await shareProject({
				user_email: params.email,
				project_key: name,
				permissions
			});
			toggleModal(false);
		} catch (e) {
			console.log("share err", e);
		}
	};

	return <ShareProjectModal onShare={onShare} toggleModal={toggleModal} isOpen={isOpen} />;
};
