type Name = string | null;

export const getCommentUserName = (fn: Name, ln: Name, username: Name, email: Name) => {
	let displayName = "";
	if (fn && ln) {
		displayName = `${fn} ${ln}`;
		return displayName;
	} else if (fn && !ln) {
		displayName = fn;
	} else if (ln && !fn) {
		displayName = ln;
	} else if (!fn && !ln) {
		if (username) {
			displayName = username;
			return displayName;
		}
	}
	return email ?? "";
};
