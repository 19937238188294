import { useAction, useAtom } from "@reatom/npm-react";
import { useKeyPress } from "ahooks";
import { Flex, Typography } from "antd";
import { FC, Fragment, useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editingShotAtom } from "@/features/highlight-script";
import { SceneTitle } from "@/features/highlight-script/ui/scene-title";
import { getProjectByNameAction } from "@/entities/projects";
import { backAction, forwardAction, sceneListAtom, scrollToShotIdAtom } from "@/entities/script";

import { isGuestAtom } from "@/entities/viewer";
import { Shot } from "./shot";

import "./HighlightScript.scss";

export const HighlightScript: FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [editingShot] = useAtom(editingShotAtom);
	const [sceneList] = useAtom(sceneListAtom);
	const historyBack = useAction(backAction);
	const historyForward = useAction(forwardAction);
	const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
	const descriptionElementRef = useRef<HTMLDivElement | null>(null);
	const [scrollToShotId] = useAtom(scrollToShotIdAtom);
	const fromEdit = useRef(false);
	const [isGuest] = useAtom(isGuestAtom);
	const [project] = useAtom(getProjectByNameAction.dataAtom);

	useKeyPress(["ctrl.z", "meta.z"], () => {
		if (!editingShot && id && !isGuest) {
			historyBack(id);
		}
	}, { exactMatch: true });

	useKeyPress(["shift.ctrl.z", "shift.meta.z"], () => {
		if (!editingShot && id && !isGuest) {
			historyForward(id);
		}
	});

	useEffect(() => {
		if (location.state?.from === "shot-editing") {
			fromEdit.current = true;
		} else {
			fromEdit.current = false;
		}
	}, []);

	useEffect(() => {
		if (!descriptionElementRef.current) return;

		if (!scrollToShotId && !location.hash) return;

		const anchorId = scrollToShotId || location.hash.slice(1);

		const element = descriptionElementRef.current.querySelector(`[id="${anchorId}"]`);

		if (!fromEdit.current) {
			navigate(`/storyboard/${id}/script#${anchorId}`);
		}

		element?.scrollIntoView({ behavior: "smooth" });

		return () => {
			fromEdit.current = false;
		};
	}, [scrollToShotId]);

	const lastShots = sceneList[sceneList.length - 1]?.shots;
	const lastId = lastShots?.[lastShots.length - 1]?.id;

	return (
		<Flex ref={descriptionElementRef} vertical className="highlight-the-shots full-height gap-xs">
			<Typography.Text className="text-center highlight-the-shots__title">“{project?.projectName}”</Typography.Text>
			<Flex vertical>
				{sceneList.map((scene) => (
					<Fragment key={scene.id}>
						<SceneTitle sceneId={scene.id} idx={scene.idx} title={scene.title} />
						{scene.shots.map((shot) => (
							<section id={`${shot.id}`}>
								<Shot
									key={`${shot.id}-${shot.description}`}
									sceneId={scene.id}
									shotId={shot.id}
									color={shot.color}
									title={shot.title}
									content={shot.prompt}
									shotIdx={shot.idx}
									isAddButtonDisabled={isAddButtonDisabled}
									setIsAddButtonDisabled={setIsAddButtonDisabled}
									sceneName={scene.title}
									projectLastShotId={lastId}
									shotsLength={lastShots.length}
								/>
							</section>
						))}
					</Fragment>
				))}
			</Flex>
		</Flex>
	);
};
