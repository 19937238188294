import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { CommentSidebar } from "@/pages/moodboard/ui/comment-sidebar";
import { ShotOutlineWrapper } from "@/pages/script/ui/shot-outline-wrapper";
import { CommentsScriptOutline } from "@/features/comments-script-outline";
import { isGuestAtom } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { usePermission } from "@/shared/hooks";
import { ActionRestrictor } from "@/shared/ui";
import { scriptSidebarAtom } from "../model";

import "./RightSidebarScript.scss";

export const RightSidebarScript: FC = () => {
	const [scriptSidebar, setScriptSidebar] = useAtom(scriptSidebarAtom);
	const [isGuest] = useAtom(isGuestAtom);
	const canComment = usePermission({ page: "script", group: "comment" });

	return (
		<>
			<Flex vertical className="shot-outline">
				<Flex className="shot-outline__header gap-s">
					<Typography.Text
						onClick={() => setScriptSidebar("shot-outline")}
						className={cn(TEXT_CLASSNAMES.HeadlineH4, COLOR_CLASSNAMES.TextTertiary, "pointer", {
							[TEXT_CLASSNAMES.HeadlineH3]: scriptSidebar === "shot-outline",
							[COLOR_CLASSNAMES.TextPrimary]: scriptSidebar === "shot-outline"
						})}>Shot Outline</Typography.Text>
					<ActionRestrictor show={canComment || !isGuest}>
						<Typography.Text
							onClick={() => setScriptSidebar("comments")}
							className={cn(TEXT_CLASSNAMES.HeadlineH4, COLOR_CLASSNAMES.TextTertiary, "pointer", {
								[TEXT_CLASSNAMES.HeadlineH3]: scriptSidebar === "comments",
								[COLOR_CLASSNAMES.TextPrimary]: scriptSidebar === "comments"
							})}>Comments</Typography.Text>
					</ActionRestrictor>
				</Flex>
				<div className="shot-outline__divider" />
				<div style={{ overflowY: "scroll", boxSizing: "border-box", height: "100%" }}>
					{scriptSidebar === "shot-outline" && <ShotOutlineWrapper />}
					<ActionRestrictor show={canComment || !isGuest}>
						<CommentSidebar>
							{scriptSidebar === "comments" && <CommentsScriptOutline />}
						</CommentSidebar>
					</ActionRestrictor>
				</div>
			</Flex>
		</>
	);
};
