import { useAction, useAtom } from "@reatom/npm-react";
import { Col, Flex, Row } from "antd";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CommentSidebar } from "@/pages/moodboard/ui/comment-sidebar";
import { CommentsScriptOutline } from "@/features/comments-script-outline";
import {
	CharacterActions,
	CharacterCard,
	CharacterHeader,
	RightSidebarCharacter,
	getCharactersAction
} from "@/entities/character";
import { isGuestAtom } from "@/entities/viewer";
import { usePermission } from "@/shared/hooks";
import { ActionRestrictor, Spinner } from "@/shared/ui";

import "./Character.scss";

export const Character: FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const initCharacters = useAction(getCharactersAction);

	const [characterList] = useAtom(getCharactersAction.dataAtom);
	const [status] = useAtom(getCharactersAction.statusesAtom);
	const [isGuest] = useAtom(isGuestAtom);
	const canComment = usePermission({ page: "characters", group: "comment" });

	const handleNext = () => {
		navigate(`/storyboard/${id}/storyboard`);
	};

	useEffect(() => {
		if (id) {
			initCharacters(id);
		}
	}, []);

	return (
		<Flex className="full-width full-height">
			<Flex vertical className="character full-height pointer gap-l">
				<CharacterHeader />
				{status.isPending
					? (
						<Flex align="center" justify="center" className="full-width full-height">
							<Spinner />
						</Flex>
					)
					: (
						<>
							<Row gutter={[16, 16]} style={{ padding: "0 8px", overflow: "auto" }}>
								{characterList.map((card) => (
									<Col key={card.id}>
										<CharacterCard key={card.id} {...card} />
									</Col>
								))}
							</Row>
							<CharacterActions type="next" onNext={handleNext} />
						</>
					)}

			</Flex>
			<ActionRestrictor show={!isGuest || canComment}>
				<RightSidebarCharacter>
					<CommentSidebar>
						<CommentsScriptOutline />
					</CommentSidebar>
				</RightSidebarCharacter>
			</ActionRestrictor>
		</Flex>
	);
};
