import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC } from "react";

import { projectsListAtom, YourStoryboard } from "@/entities/projects";

import "./FullProject.scss";

export const FullProject: FC = () => {
	const [projects] = useAtom(projectsListAtom);

	return (
		<Flex wrap="wrap" className="full-project gap-s">
			{projects?.map(({ name, date, img, projectName }) => (
				<YourStoryboard key={name} name={name} projectName={projectName} date={date} img={img} />
			))}
		</Flex>
	);
};
