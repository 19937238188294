import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, notification, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SceneShotOutline } from "@/features/shot-outline";
import { getProjectByNameAction } from "@/entities/projects";
import { addSceneAction, sceneListAtom } from "@/entities/script";
import { isGuestAtom } from "@/entities/viewer";
import plus from "@/assets/shared/plus.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

export const ShotOutlineWrapper: FC = () => {
	const { id } = useParams();
	const [isGuest] = useAtom(isGuestAtom);

	const getProject = useAction(getProjectByNameAction);
	const addScene = useAction(addSceneAction);

	const [sceneList] = useAtom(sceneListAtom);
	const [project] = useAtom(getProjectByNameAction.dataAtom);
	const [api, contextHolder] = notification.useNotification();

	const lastShots = sceneList[sceneList.length - 1]?.shots;
	const lastId = lastShots?.[lastShots.length - 1]?.id;

	const handleAddScene = async () => {
		if (id && project) {
			await addScene(id, { country: project.country ?? "", city: project.city ?? "" });

			api.success({
				message:
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>New scene was added</Typography.Text>,
				placement: "top",
				closeIcon: null,
				className: "scene-added-notification",
				style: { width: 225 }
			});
		}
	};

	useEffect(() => {
		if (id) {
			getProject(id);
		}
	}, []);

	return (
		<Flex vertical className="shot-outline__body">
			{contextHolder}
			<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary)} style={{ paddingTop: 16 }}>
				Select a shot to manage its settings or to add new shots to the scene. This panel helps you organize and refine the flow of your scenes efficiently.
			</Typography.Text>
			{!isGuest && <Button onClick={handleAddScene} className="full-width flex-ctr-ctr gap-xxs shot-outline__button" type="primary">
				<img src={plus} alt="plus" className="shot-outline__img" />
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add scene</Typography.Text>
			</Button>}
			<Flex vertical className="gap-xs">
				{sceneList.map((scene) => (
					<SceneShotOutline
						sceneId={scene.id}
						sceneIdx={scene.idx}
						sceneTitle={scene.title}
						key={scene.id}
						shots={scene.shots}
						projectLastShotId={lastId}
					/>
				))}
			</Flex>
		</Flex>
	);
};
