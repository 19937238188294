import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import { FC, useState } from "react";

import { useParams } from "react-router-dom";
import { addShotToSceneAction, deleteShotAction, DeleteShotModal, TShot } from "@/entities/script";
import { isGuestAtom } from "@/entities/viewer";
import plus from "@/assets/shared/plus.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useOpenModal } from "@/shared/hooks";
import { ActionRestrictor } from "@/shared/ui";
import { DeleteSceneModal } from "./DeleteSceneModal.tsx";

import "./SceneShotOutline.scss";
import { SceneShotItem } from "./SceneShotItem.tsx";
interface IScene {
	shots: TShot[];
	sceneTitle: string;
	sceneId: string;
	sceneIdx: number;
	projectLastShotId: string;
}

export const SceneShotOutline: FC<IScene> = ({ sceneTitle, shots, sceneId, sceneIdx, projectLastShotId }) => {
	const { id } = useParams();
	const addShot = useAction(addShotToSceneAction);
	const deleteShot = useAction(deleteShotAction);
	const [open, toggleModal] = useOpenModal();
	const [activeShotId, setActiveShotId] = useState<string>("");
	const [isLastShot, setIsLastShot] = useState(false);
	const lastShotId = shots[shots.length - 1]?.id;
	const isEmptyScene = shots.length === 1;
	const [isGuest] = useAtom(isGuestAtom);

	const handleAddShot = () => {
		if (id) {
			addShot(id, { sceneId, shotId: lastShotId });
		}
	};

	const handleDeleteShot = async (shotId: string, description: string) => {
		const isContent = !!description.length;
		const isLast = projectLastShotId === shotId;

		if (isEmptyScene && isLast) {
			setIsLastShot(true);
			setActiveShotId(activeShotId);
			toggleModal(true);
		} else {
			if (isContent) {
				setActiveShotId(shotId);
				toggleModal(true);
			} else {
				if (id) {
					await	deleteShot(id, { sceneId, shotId });
				}
			}
		}
	};

	return (
		<Flex vertical className="gap-xs scene">
			<Flex align="center" justify="space-between" gap={2} className="scene__title">
				<Typography.Text className={TEXT_CLASSNAMES.SmSemibold} ellipsis>Scene {sceneIdx + 1} "{sceneTitle}"</Typography.Text>
				<ActionRestrictor show={!isGuest}>
					<DeleteSceneModal sceneId={sceneId} />
				</ActionRestrictor>
			</Flex>
			<Flex vertical className="gap-xxs">
				{shots.map((shot) => (
					<a key={shot.id} href={`#${shot.id}`}>
						<SceneShotItem key={shot.id} sceneId={sceneId} onDeleteShot={handleDeleteShot} {...shot}/>
					</a>
				))}
				<DeleteShotModal
					sceneId={sceneId}
					shotId={activeShotId}
					toggleModal={toggleModal}
					isOpen={open}
					isLast={isLastShot}
				/>
				<ActionRestrictor show={!isGuest}>
					<Flex onClick={handleAddShot} align="center" className="gap-xxs pointer">
						<img src={plus} alt="plus" className="scene__img" />
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Add shot</Typography.Text>
					</Flex>
				</ActionRestrictor>
			</Flex>
		</Flex>
	);
};
