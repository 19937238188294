import { Flex, Typography } from "antd";
import cn from "classnames";
import { useCallback, useState } from "react";

import addButton from "@/assets/shared/plus_green.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { InviteEmailField } from "./InviteEmailField.tsx";
import { InviteProps, SelectProps } from "./types.ts";

import "./InviteEmail.scss";

type Props = SelectProps & InviteProps & {
	title: string;
};

export function InviteEmail ({ options, placeholder, onFinish, title }: Props) {
	const [fields, setFields] = useState([0]);

	const onDelete = useCallback((id: number) => {
		setFields(fields.filter((field) => field !== id));
	}, []);

	const onAdd = () => {
		setFields((prevState) => [...prevState, prevState.length]);
	};

	return (
		<Flex vertical className="gap-m">
			{!!title && <Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>{title}</Typography.Text>}
			<Flex vertical className="gap-xs">
				<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Invite with Email</Typography.Text>
				{fields.map((value) => (
					<InviteEmailField
						onDelete={onDelete}
						id={value}
						key={value.toString(36)}
						onFinish={onFinish}
						options={options}
						placeholder={placeholder}
					/>
				))}
				<Flex onClick={onAdd} align="flex-end" role="button" className="gap-4 pointer">
					<img src={addButton} alt="add" />
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsSemibold, COLOR_CLASSNAMES.Green600)}>Add</Typography.Text>
				</Flex>
			</Flex>
		</Flex>
	);
}
