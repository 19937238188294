import { UserOutlined } from "@ant-design/icons";
import { useAction, useAtom } from "@reatom/npm-react";
import { Avatar, Flex, Layout, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { FeedbackModal } from "@/widgets/feedback-modal";
import { getAccountAction } from "@/entities/account";
import { isGuestAtom, logoutAction } from "@/entities/viewer";
import feedback from "@/assets/shared/feedback.svg";
import logoutImg from "@/assets/shared/logout.svg";
import logo from "@/assets/shared/sidebar_logo.svg";

import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names";
import { NOT_RELEASE } from "@/shared/const/release.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { useOpenModal } from "@/shared/hooks";
import { mobileCheck } from "@/shared/methods";
import { TPageRoutes } from "@/shared/types/page";
import { ActionRestrictor } from "@/shared/ui";
import { ProjectsSVG, SettingsSVG, TeamSVG } from "@/assets/components";

import "./HomeLeftSidebar.scss";

export const HomeLeftSidebar: FC = () => {
	const [feedbackModal, toggleFeedbackModal] = useOpenModal();
	const logout = useAction(logoutAction);
	const [account] = useAtom(getAccountAction.dataAtom);
	const getAccount = useAction(getAccountAction);
	const location = useLocation();
	const navigate = useNavigate();
	const [activePage, setActivePage] = useState<TPageRoutes>("projects");
	const settingsRoutes = ["profile", "settings", "billing", "view", "account", "team", "preferences", "members"];
	const isMobile = mobileCheck();
	const [isGuest] = useAtom(isGuestAtom);

	const handleRedirect = (page: TPageRoutes) => {
		setActivePage(page);
		navigate(`/main/${page}`);
	};

	const goMain = () => {
		navigate("/main/projects");
	};

	useEffect(() => {
		const pathnameArray = location.pathname.split("/");
		const routeName = pathnameArray[pathnameArray.length - 1];

		setActivePage(routeName as TPageRoutes);
	}, [location.pathname]);

	useEffect(() => {
		getAccount(true);
	}, []);

	if (account?.data && isMobile && !NOT_RELEASE) {
		return <Navigate to="/desktop-only" />;
	}

	return (
		<Layout hasSider className="home-left-sidebar">
			<Layout.Sider
				className="home-left-sidebar__sidebar"
				style={{
					overflow: "auto",
					height: "100vh",
					position: "fixed",
					left: 0,
					top: 0,
					bottom: 0,
					background: COLOR_NAMES.MetalGray50
				}}
			>
				<Flex vertical justify="space-between" className="full-height">
					<Flex vertical className="gap-l">
						<Flex onClick={goMain} vertical align="center" className="gap-xs">
							<img className="home-left-sidebar__logo" src={logo} alt="blooper" />
						</Flex>
						<Flex vertical className="gap-s">
							<Flex className="home-left-sidebar__user-info gap-xxs">
								{account?.data?.avatar
									? (
										<img className="home-left-sidebar__avatar" src={account?.data?.avatar} alt="avatar" />
									)
									: (
										<Avatar style={{ minWidth: 34 }} shape="square" size={34} icon={<UserOutlined />} />
									)}
								<Flex vertical style={{ overflow: "hidden" }}>
									<Typography.Paragraph style={{ width: 95 }} ellipsis={{ rows: 2 }} className={TEXT_CLASSNAMES.XxsSemibold}>
										{account?.data?.username ?? account?.data?.email}
									</Typography.Paragraph>
									<Typography.Text style={{ textTransform: "capitalize" }} className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
										{account?.data?.role}
									</Typography.Text>
								</Flex>
							</Flex>
							<Flex vertical className="gap-xxs">
								<Flex
									gap={12}
									onClick={() => handleRedirect("projects")}
									className={cn("home-left-sidebar__page pointer", {
										"home-left-sidebar__page__active": activePage === "projects"
									})}
									align="center"
								>
									<ProjectsSVG fill={activePage === "projects" ? COLOR_NAMES.TextBrand : COLOR_NAMES.MetalGray700} />
									<Typography.Text
										className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.MetalGray700, {
											[COLOR_CLASSNAMES.TextPrimary]: activePage === "projects"
										})}
									>
										Projects
									</Typography.Text>
								</Flex>
								<ActionRestrictor show={!isGuest}>
									<Flex
										gap={12}
										onClick={() => handleRedirect("team-manage")}
										className={cn("home-left-sidebar__page pointer", {
											"home-left-sidebar__page__active": activePage === "team-manage"
										})}
										align="center"
									>
										<TeamSVG fill={activePage === "team-manage" ? COLOR_NAMES.TextBrand : COLOR_NAMES.MetalGray700} />
										<Typography.Text
											className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.MetalGray700, {
												[COLOR_CLASSNAMES.TextPrimary]: activePage === "team-manage"
											})}
										>
											Team
										</Typography.Text>
									</Flex>
								</ActionRestrictor>
								<Flex
									gap={12}
									onClick={() => handleRedirect("settings/profile")}
									className={cn("home-left-sidebar__page pointer", {
										"home-left-sidebar__page__active": settingsRoutes.includes(activePage)
									})}
									align="center"
								>
									<SettingsSVG fill={settingsRoutes.includes(activePage) ? COLOR_NAMES.TextBrand : COLOR_NAMES.MetalGray700} />
									<Typography.Text
										className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.MetalGray700, {
											[COLOR_CLASSNAMES.TextPrimary]: settingsRoutes.includes(activePage)
										})}
									>
										Settings
									</Typography.Text>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
					<Flex vertical className="gap-4">
						<Flex
							role="button"
							align="center"
							gap={12}
							className="home-left-sidebar__page pointer"
							onClick={() => toggleFeedbackModal(true)}
						>
							<img src={feedback} alt="feedback"/>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Feedback</Typography.Text>
						</Flex>
						<Flex
							role="button"
							align="center"
							gap={12}
							className="home-left-sidebar__page pointer"
							onClick={logout}
						>
							<img src={logoutImg} alt="logout"/>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Logout</Typography.Text>
						</Flex>
					</Flex>
					<FeedbackModal toggleModal={toggleFeedbackModal} isOpen={feedbackModal} />
				</Flex>
			</Layout.Sider>
			<Layout style={{ marginLeft: 200 }}>
				<Layout.Content style={{ padding: 0, overflow: "initial", background: "rgb(234, 234, 236)" }}>
					<Outlet />
				</Layout.Content>
			</Layout>
		</Layout>
	);
};
