import { action, atom } from "@reatom/core";
import { PermissionPage } from "@/shared/types/share.ts";
import { TPages } from "../types.ts";

const defaultActivePages: TPages = {
	storyboards: true,
	moodboard: true,
	script: true,
	characters: true
};

const defaultDisablePages: TPages = {
	storyboards: false,
	moodboard: false,
	script: false,
	characters: false
};

export const activeSharingPagesAtom = atom(defaultActivePages, "activeSharingPagesAtom");

export const isAllAtom = atom((ctx) => {
	const activePages = ctx.spy(activeSharingPagesAtom);
	return !Object.values(activePages).some((el) => !el);
});

export const togglePageAction = action((ctx, page: PermissionPage) => {
	activeSharingPagesAtom(ctx, (prev) => ({
		...prev,
		[page]: !prev[page]
	}));
});

export const toggleAllAction = action((ctx) => {
	const isAll = ctx.get(isAllAtom);

	if (isAll) {
		activeSharingPagesAtom(ctx, defaultDisablePages);
	} else {
		activeSharingPagesAtom(ctx, defaultActivePages);
	}
});
