import axios from "axios";
import { api } from "@/shared/api";
import {
	CreateGuestUserParams,
	InvitationVerifyParams,
	Permission,
	TAuthCode,
	TCredentials,
	TRegisterCredentials,
	TRegisterKey
} from "./types";
import { TResponse } from "@/vite-env";

export const loginResource = async (credentials: TCredentials): TResponse<TRegisterKey> => api.post("/auth/login", {
	...credentials
});

export const createUserResource = (credentials: TRegisterCredentials): TResponse<unknown> => api.post("/auth/add_user", {
	...credentials
});

export const createUserGoogleResource = (credentials: TAuthCode): TResponse<TRegisterKey> => api.post("/auth/add_user/google", {
	...credentials
});

export const loginUserGoogleResource = (credentials: TAuthCode): TResponse<TRegisterKey> => api.post("/auth/login/google", {
	...credentials
});

export const deleteUserResource = () => api.delete("/auth/");

export const verifyEmailResource = (key: string): TResponse<TRegisterKey> => api({
	method: "PATCH",
	url: "/auth/verify",
	params: {
		verification_code: key
	}
});

export const linkProfileGoogleResource = (token: string) =>
	api.post("/auth/link_profile/google", {
		access_token: token
	});

export const verifyInvitationResource = (params: InvitationVerifyParams): TResponse<TRegisterKey> => api.post("/auth/add_user/invitation", params);

export const initResetPasswordResource = (email: string) => {
	return axios({
		method: "POST",
		url: `${import.meta.env.VITE_API_URL}/settings/reset_password/init`,
		data: {
			email
		}
	});
};

export const validateResetPasswordResource = (email: string, code: string): TResponse<TRegisterKey> => {
	return axios({
		method: "POST",
		url: `${import.meta.env.VITE_API_URL}/settings/reset_password/validate?code=${code}`,
		data: {
			email
		}
	});
};

export const resetPasswordResource = (password: string, token: string) => {
	// @ts-ignore
	return axios.put(`${import.meta.env.VITE_API_URL}/settings/reset_password/update`, {
		password
	}, {
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const refreshTokenResource = (accessToken: string, refreshToken: string): TResponse<TRegisterKey> => {
	return axios.post(`${import.meta.env.VITE_API_URL}/auth/refresh`, {
		access_token: accessToken,
		refresh_token: refreshToken
	});
};

export const createGuestUserResource = (params: CreateGuestUserParams): TResponse<TRegisterKey> => api.post("/auth/guest_sign_up", params);

export const getUserPermissionResource = (token: string): TResponse<Permission[]> =>
	axios({
		method: "GET",
		url: `${import.meta.env.VITE_API_URL}/auth/permissions/`,
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
