// 1: full body shot, 2: portrait, 3: profile

import kid1_1 from "@/assets/character/kid/1.1.png";
import kid1_2 from "@/assets/character/kid/1.2.png";
import kid1_3 from "@/assets/character/kid/1.3.png";

import kid2_1 from "@/assets/character/kid/2.1.png";
import kid2_2 from "@/assets/character/kid/2.2.png";
import kid2_3 from "@/assets/character/kid/2.3.png";

import kid3_1 from "@/assets/character/kid/3.1.png";
import kid3_2 from "@/assets/character/kid/3.2.png";
import kid3_3 from "@/assets/character/kid/3.3.png";

import men1_1 from "@/assets/character/men/1.1.png";
import men1_2 from "@/assets/character/men/1.2.png";
import men1_3 from "@/assets/character/men/1.3.png";

import men2_1 from "@/assets/character/men/2.1.png";
import men2_2 from "@/assets/character/men/2.2.png";
import men2_3 from "@/assets/character/men/2.3.png";

import men3_1 from "@/assets/character/men/3.1.png";
import men3_2 from "@/assets/character/men/3.2.png";
import men3_3 from "@/assets/character/men/3.3.png";

import women1_1 from "@/assets/character/women/1.1.png";
import women1_2 from "@/assets/character/women/1.2.png";
import women1_3 from "@/assets/character/women/1.3.png";

import women2_1 from "@/assets/character/women/2.1.png";
import women2_2 from "@/assets/character/women/2.2.png";
import women2_3 from "@/assets/character/women/2.3.png";

import women3_1 from "@/assets/character/women/3.1.png";
import women3_2 from "@/assets/character/women/3.2.png";
import women3_3 from "@/assets/character/women/3.3.png";

export type Character = "man" | "woman" | "kid";
export type CharacterNumber = "1" | "2" | "3";
export type CharacterImageNumber = "1" | "2" | "3";

// type CharacterKeys = "images" | "description" | "name"

type Characters = Record<Character, Record<CharacterNumber, Record<CharacterImageNumber | "description", string>>>

// {
// 	"man_1": "A white man, with side parted dark light hair, a mustache and a slim face. He wears a white t shirt and dark blue jeans and black sneakers.",
// 	"man_2": "An asian man in his 30s, with slim face, clean shaven and side parted dark hair. He wears a black t shirt and dark blue jeans and white sneakers.",
// 	"man_3": "A Nigerian man with very short afro fade and a  beard and dark skin. He wears a white t shirt and dark open blazer with dark blue jeans and dark dress shoes.",
// 	"woman_1": "A white woman, dark hair that is parted in the middle and in a low ponytail, with a delicate face and high cheek bones. She wears a white button down shirt with rolled up sleeves and black dress pants and black loafers.",
// 	"woman_2": "A Chinese woman with shoulder length straight black hair with bangs and slanted eyes and slim figure. She wears a white t shirt, a dark blazer with dark pants and black shoes.",
// 	"woman_3": "A Nigerian wife in her 30s with a wide face, dark eyes, short dread locks worn in a bun. She wears a white t shirt tucked into dark blue skinny jeans and white sneakers.",
// 	"kid_1": "A 10 year old tall white boy with blonde hair and bright eyes. He wears a dark wool pullover, dark trousers and white sneakers.",
// 	"kid_2": "A 10 year old tall Nigerian boy with dark skin, wide face and short afro hair. He wears a white t shirt, dark knee-long shorts and white sneakers.",
// 	"kid_3": "A 10 year old tall Chinese girl with dark long hair in a high ponytail with bangs. She wears a black t shirt, dark blue jeans and black sneakers.",
// }

export const CHARACTERS: Characters = {
	man: {
		1: {
			1: men1_1,
			2: men1_2,
			3: men1_3,
			description: "A white man, with side parted dark light hair, a mustache and a slim face. He wears a white t shirt and dark blue jeans and black sneakers."
		},
		2: {
			1: men2_1,
			2: men2_2,
			3: men2_3,
			description: "An asian man in his 30s, with slim face, clean shaven and side parted dark hair. He wears a black t shirt and dark blue jeans and white sneakers."
		},
		3: {
			1: men3_1,
			2: men3_2,
			3: men3_3,
			description: "A Nigerian man with very short afro fade and a  beard and dark skin. He wears a white t shirt and dark open blazer with dark blue jeans and dark dress shoes."
		}
	},
	kid: {
		1: {
			1: kid1_1,
			2: kid1_2,
			3: kid1_3,
			description: "A 10 year old tall white boy with blonde hair and bright eyes. He wears a dark wool pullover, dark trousers and white sneakers."
		},
		2: {
			1: kid2_1,
			2: kid2_2,
			3: kid2_3,
			description: "A 10 year old tall Nigerian boy with dark skin, wide face and short afro hair. He wears a white t shirt, dark knee-long shorts and white sneakers."
		},
		3: {
			1: kid3_1,
			2: kid3_2,
			3: kid3_3,
			description: "A 10 year old tall Chinese girl with dark long hair in a high ponytail with bangs. She wears a black t shirt, dark blue jeans and black sneakers."
		}
	},
	woman: {
		1: {
			1: women1_1,
			2: women1_2,
			3: women1_3,
			description: "A white woman, dark hair that is parted in the middle and in a low ponytail, with a delicate face and high cheek bones. She wears a white button down shirt with rolled up sleeves and black dress pants and black loafers."
		},
		2: {
			1: women2_1,
			2: women2_2,
			3: women2_3,
			description: "A Chinese woman with shoulder length straight black hair with bangs and slanted eyes and slim figure. She wears a white t shirt, a dark blazer with dark pants and black shoes."
		},
		3: {
			1: women3_1,
			2: women3_2,
			3: women3_3,
			description: "A Nigerian wife in her 30s with a wide face, dark eyes, short dread locks worn in a bun. She wears a white t shirt tucked into dark blue skinny jeans and white sneakers."
		}
	}
};
