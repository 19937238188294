import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { action } from "@reatom/core";
import { callErrorAction } from "@/entities/notification";
import { Comment, CreateComment, createCommentResource, getCommentsResource } from "@/shared/api/comments";

export const getCommentsAction = reatomAsync((_ctx, id: string) => getCommentsResource(id), "getCommentsAction")
	.pipe(
		withDataAtom([], (_ctx, res) => res.data),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err)),
		withStatusesAtom()
	);

export const addCommentToBottomAction = action((ctx, message: Comment) => {
	const comments = ctx.get(getCommentsAction.dataAtom);
	getCommentsAction.dataAtom(ctx, [...comments, message]);
});

export const createCommentAction = reatomAsync((_ctx, comment: CreateComment) => {
	return createCommentResource(comment);
}, "createCommentActionAtom");
