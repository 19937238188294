import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { CommentsScriptOutline } from "@/features/comments-script-outline";
import { ArchiveBoard } from "@/entities/archive-board";
import { InspirationBoard } from "@/entities/inspiration-board";
import {
	moodBoardStatusAtom,
	RightSidebarMoodboard,
	moodBoardSidebarView,
	getMoodboardAction,
	moodBoardGridTypeAtom
} from "@/entities/moodboard";
import { SearchBoard } from "@/entities/search-board";

import { isGuestAtom } from "@/entities/viewer";
import { usePermission } from "@/shared/hooks";
import { ActionRestrictor } from "@/shared/ui";
import { MoodboardContent } from "./MoodboardContent";
import { MoodboardHeader } from "./MoodboardHeader";

import "./Moodboard.scss";
import { CommentSidebar } from "./comment-sidebar";

export const Moodboard: FC = () => {
	const { id } = useParams();
	const [moodBoardStatus] = useAtom(moodBoardStatusAtom);
	const initMoodboard = useAction(getMoodboardAction);
	const [moodBoardGridType] = useAtom(moodBoardGridTypeAtom);
	const [isGuest] = useAtom(isGuestAtom);
	const canComment = usePermission({ page: "moodboard", group: "comment" });
	const [sidebarView, changeView] = useAtom(moodBoardSidebarView);

	const isMoodBoardEmpty = moodBoardStatus === "empty";

	useEffect(() => {
		if (id) {
			initMoodboard(id);
		}
	}, [moodBoardGridType]);

	useEffect(() => {
		if (isGuest && canComment) {
			changeView("comments");
		}
	}, [isGuest, canComment, changeView]);

	console.log("sidebarView :>> ", sidebarView);
	return (
		<Flex className="full-width full-height">
			<Flex vertical className={cn("moodboard", "gap-s", { moodboard__full: !isMoodBoardEmpty })}>
				<MoodboardHeader />
				<MoodboardContent />
			</Flex>
			<ActionRestrictor show={!isGuest || canComment}>
				<RightSidebarMoodboard>
					<ActionRestrictor show={!isGuest}>
						{sidebarView === "inspiration" && <InspirationBoard />}
						{sidebarView === "archive" && <ArchiveBoard />}
						{sidebarView === "search" && <SearchBoard />}
					</ActionRestrictor>
					<CommentSidebar>
						{sidebarView === "comments" && <CommentsScriptOutline />}
					</CommentSidebar>
				</RightSidebarMoodboard>
			</ActionRestrictor>
		</Flex>
	);
};
