import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { AxiosError } from "axios";

import TagManager from "react-gtm-module";
import { callErrorAction } from "@/entities/notification";
import { addScriptToProjectAction } from "@/entities/script";
import {
	addThumbnailResource,
	createProjectResource,
	deleteProjectResource,
	getAllProjectsResource,
	getProjectResource, ProjectShareParams, shareProjectResource,
	updateProjectNameResource
} from "@/shared/api/project";
import { getProjectsStoryboardImagesResource } from "@/shared/api/storyboard";
import { TNewProject, TProject } from "../lib";

export const getProjectByNameAction = reatomAsync((_ctx, name: string) => getProjectResource(name))
	.pipe(
		withDataAtom(null, (_ctx, res) => {
			const { name: projectName, ...rest } = res.data;
			return { ...rest, projectName };
		}),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err as AxiosError))
	);

export const projectsListAtom = atom<TProject[]>([], "projectsListAtom");
export const projectsLoadingAtom = atom(false, "projectsLoadingAtom");

export const createProjectAction = action(async (ctx, project: TNewProject) => {
	// eslint-disable-next-line no-useless-catch
	try {
		const projectList = ctx.get(projectsListAtom);

		const { data } = await createProjectResource({
			name: project.name ?? "",
			brief: project.briefing ?? "",
			location_details: {
				country: project.country ?? "",
				city: project.city ?? ""
			}
		});

		await addScriptToProjectAction(ctx, project.script, data.project_key);

		projectsListAtom(ctx, [...projectList, {
			name: data.project_key ?? "",
			date: new Date().toISOString(),
			img: "",
			projectName: project.name ?? ""
		}]);

		TagManager.dataLayer({
			dataLayer: {
				event: "create_project",
				projectName: project.name ?? ""
			}
		});

		return data.project_key;
	} catch (e) {
		throw e;
	}
});

export const initProjectsAction = action(async (ctx) => {
	try {
		projectsLoadingAtom(ctx, true);
		const { data } = await getAllProjectsResource();

		const projects: TProject[] = data.names.map((project) => ({
			date: project.creation_time,
			img: project.thumbnail_url,
			name: project.key,
			projectName: project.name
		}));

		projectsListAtom(ctx, projects);
	} catch (e) {
		callErrorAction(ctx, e);
	} finally {
		projectsLoadingAtom(ctx, false);
	}
});

export const renameProjectByIdAction = action(async (ctx, oldName: string, newName: string) => {
	try {
		const projectList = ctx.get(projectsListAtom);

		await updateProjectNameResource(oldName, newName);

		projectsListAtom(ctx, projectList.map((project) => ({
			...project,
			projectName: oldName === project.name ? newName : project.projectName
		})));
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const deleteProjectByIdAction = action(async (ctx, name: string) => {
	try {
		const projectList = ctx.get(projectsListAtom);

		await deleteProjectResource(name);

		projectsListAtom(ctx, projectList.filter((project) => project.name !== name));
	} catch (e) {
		console.log("e :>> ", e);
		callErrorAction(ctx, e as AxiosError);
	}
});

export const uploadThumbnailAction = reatomAsync((ctx, name: string, formData: FormData) => addThumbnailResource(name, formData, ctx.controller), {
	name: "uploadThumbnailAction",
	onReject: (ctx, err) => {
		callErrorAction(ctx, err);
	}
}).pipe(withStatusesAtom());

export const getProjectStoryboardImagesActions = reatomAsync((_ctx, key: string) => getProjectsStoryboardImagesResource(key))
	.pipe(
		withDataAtom([], (_ctx, res) => {
			const images = Object.entries(res.data).map(([id, img]) => ({
				img,
				id
			}));

			return images;
		}),
		withErrorAtom(callErrorAction),
		withStatusesAtom()
	);

export const shareProjectAction = reatomAsync((_ctx, params: ProjectShareParams) => shareProjectResource(params), "shareProjectAction")
	.pipe(
		withDataAtom(),
		withErrorAtom(callErrorAction),
		withStatusesAtom()
	);
