import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import { Descendant } from "slate";
import { updateShotDescriptionActions } from "@/features/shot-details/model";
import { isGuestAtom } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

type Props = {
	description?: string;
	prompt?: Descendant[];
}

export const ShotDescriptionEdit: FC<Props> = ({ description, prompt }) => {
	const [descriptionValue, setDescriptionValue] = useState(description);
	const descriptionUpdate = useAction(updateShotDescriptionActions);
	const [isGuest] = useAtom(isGuestAtom);

	return (
		<>
			<div className="shot-details-info__divider" />
			<Flex vertical wrap="wrap" className="shot-details-info__row gap-xs">
				<Flex vertical className="gap-xxs">
					<Typography.Text
						className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Description</Typography.Text>
					<Typography.Paragraph
						editable={!isGuest && {
							onChange: (value) => {
								setDescriptionValue(value);
								descriptionUpdate(value, prompt ?? []);
							}
						}}
						className={TEXT_CLASSNAMES.XsRegular}
					>
						{descriptionValue}
					</Typography.Paragraph>
				</Flex>
			</Flex>
		</>
	);
};
