import { useAction, useAtom } from "@reatom/npm-react";
import { useWebSocket } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { CommentWrapper } from "@/features/comments-script-outline/ui/comment/Comment.tsx";
import { TextField } from "@/features/comments-script-outline/ui/text-field";
import { viewerAtom } from "@/entities/viewer";
import { Comment as CommentParams } from "@/shared/api/comments";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { Spinner } from "@/shared/ui";
import { addCommentToBottomAction, getCommentsAction } from "../model";
import { Comment } from "./comment";
import emptyComments from "@/assets/comments/empty.svg";

export const CommentsScriptOutline: FC = () => {
	const { id } = useParams();
	const commentsRef = useRef<HTMLDivElement>(null);
	const getComments = useAction(getCommentsAction);
	const [comments] = useAtom(getCommentsAction.dataAtom);
	const [viewer] = useAtom(viewerAtom);
	const addCommentToBottom = useAction(addCommentToBottomAction);
	const [commentsStatus] = useAtom(getCommentsAction.statusesAtom);

	const ORIG_API_SOCKET_URL = `${import.meta.env.VITE_API_URL}`
		.replace("https", "wss")
		.replace("/api", "");

	const { latestMessage } = useWebSocket(`${ORIG_API_SOCKET_URL}/ws?token=${viewer?.key}&project_key=${id}`);

	useEffect(() => {
		if (id) {
			getComments(id);
		}
	}, []);

	useEffect(() => {
		if (latestMessage?.data) {
			const data = JSON.parse(latestMessage?.data) as CommentParams;
			addCommentToBottom(data);
		}
	}, [latestMessage]);

	useEffect(() => {
		if (!commentsRef.current) return;
		const lastComment = commentsRef.current.querySelector(".comment:last-child");
		lastComment?.scrollIntoView({ behavior: "smooth" });
	}, [comments]);

	const renderComments = () => {
		if (commentsStatus.isPending) {
			return <Flex vertical align="center" justify="center" className="full-height gap-xs">
				<Spinner />
			</Flex>;
		}
		if (!comments.length) {
			return (
				<Flex vertical align="center" justify="center">
					<img width={180} src={emptyComments} alt="comments"/>
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsSemibold, COLOR_CLASSNAMES.TextSecondary)}>No comments yet</Typography.Text>
				</Flex>
			);
		}
		return comments.filter((c) => c.parent_id === null).map((comment) => {
			return (
				<CommentWrapper key={comment.id}>
					<Comment {...comment} />
				</CommentWrapper>
			);
		});
	};

	return (
		<Flex vertical style={{ height: "100%" }} ref={commentsRef}>
			<Flex style={{
				boxSizing: "border-box",
				overflowY: "auto"
			}} flex={1} vertical>
				{renderComments()}
			</Flex>
			<div style={{ padding: 16 }}>
				<TextField placeholder="Add a comment" />
			</div>
		</Flex>
	);
};
