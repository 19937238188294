import { useAtom } from "@reatom/npm-react";
import { useHover } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";

import { ColorShotSelection } from "@/features/color-shot-select";
import { useIsShotActive } from "@/features/highlight-script/ui/shot/Shot.tsx";
import { TShot } from "@/entities/script";

import { isGuestAtom } from "@/entities/viewer";
import deleteImg from "@/assets/shared/delete.svg";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { ActionRestrictor } from "@/shared/ui";

interface ISceneShotItem extends TShot {
    sceneId: string;
    onDeleteShot: (id: string, description: string) => void
}

export const SceneShotItem: FC<ISceneShotItem> = ({ onDeleteShot, sceneId, title, color, id, idx, description }) => {
	const elementRef = useRef(null);
	const isHover = useHover(elementRef);
	const isActive = useIsShotActive(id);
	const [isGuest] = useAtom(isGuestAtom);

	return (
		<Flex ref={elementRef} align="center" justify="space-between" className={cn("scene__shot pointer", {
			[`scene__shot--outline-${color}`]: isHover || isActive,
			"scene__shot--outline-hovered": isHover || isActive
		})}
		>
			<Flex align="center" className="gap-xxs">
				<ColorShotSelection
					placement="left"
					defaultColor={color}
					shotId={id}
					sceneId={sceneId}
				/>
				<Typography.Text style={{ whiteSpace: "nowrap", maxWidth: 250 }} ellipsis className={TEXT_CLASSNAMES.XsRegular}>Shot {idx + 1} {!!title.length && `"${title}"`}</Typography.Text>
			</Flex>
			<ActionRestrictor show={!isGuest}>
				<div role="button" onClick={() => onDeleteShot(id, description)}>
					<img className="scene__shot--delete" src={deleteImg} alt="delete"/>
				</div>
			</ActionRestrictor>
		</Flex>
	);
};
