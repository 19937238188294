import { FC, PropsWithChildren } from "react";

type Props = {
	show: boolean;
}

export const ActionRestrictor: FC<PropsWithChildren<Props>> = ({ show, children }) => {
	if (show) {
		return (
			<>
				{children}
			</>
		);
	}

	return null;
};
