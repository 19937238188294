import { UserOutlined } from "@ant-design/icons";
import { useAtom } from "@reatom/npm-react";
import { Avatar, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, PropsWithChildren, useMemo, useRef } from "react";
// import blackCheck from "@/assets/shared/arrow_check_circle_black.svg";
import { getCommentsAction } from "@/features/comments-script-outline/model";
import { TextField } from "@/features/comments-script-outline/ui/text-field";
// import greenCheck from "@/assets/shared/arrow_check_circle_green.svg";
import messageIcon from "@/assets/shared/message.svg";
import { Comment as CommentProps } from "@/shared/api/comments";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useOpenModal, useOutsideClick } from "@/shared/hooks";
import { timeAgo } from "@/shared/methods";
import { ActionRestrictor } from "@/shared/ui";

import "./Comment.scss";
import { getCommentUserName } from "../../lib";

type Props = CommentProps

// eslint-disable-next-line camelcase
export const Comment: FC<Props> = ({ avatar, username, email, first_name, last_name, created_at, message, id }) => {
	const [open, toggleOpen] = useOpenModal();
	const ref = useRef(null);
	const [comments] = useAtom(getCommentsAction.dataAtom);

	const memoUserName = useMemo(() => {
		return getCommentUserName(first_name, last_name, username, email);
	}, [username, email, first_name, last_name]);

	const replies = comments.filter((comment) => comment.parent_id === id);

	const ago = () => {
		const date = new Date(created_at);

		return timeAgo(date);
	};

	useOutsideClick(ref, () => {
		toggleOpen(false);
	});

	return (
		<Flex ref={ref} id={id} vertical className="gap-xxs">
			<Flex justify="space-between" align="center">
				<Flex align="center" className="gap-4">
					<Flex align="center" className="gap-xxs">
						<Avatar size={24} icon={avatar ? <img src={avatar} alt="avatar" /> : <UserOutlined />} />
						{/* eslint-disable-next-line camelcase */}
						<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>{memoUserName}</Typography.Text>
					</Flex>
					{/* eslint-disable-next-line camelcase */}
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>{ago()}</Typography.Text>
				</Flex>
				{/* <img src={greenCheck} alt="check"/> */}
			</Flex>
			<Flex ref={ref}>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					{message}
				</Typography.Text>
			</Flex>
			<ActionRestrictor show={!!replies.length && open}>
				<Flex style={{ paddingTop: 8, paddingBottom: 8 }} vertical className="gap-xs">
					{replies.map((reply) => (
						<Comment key={`${id}-${reply.id}`} {...reply} />
					))}
				</Flex>
			</ActionRestrictor>
			<div style={{ opacity: open ? 1 : 0, height: open ? "auto" : 0, zIndex: open ? 1 : -1 }}>
				<TextField placeholder="Add a comment" parentId={id} />
			</div>
			<ActionRestrictor show={!open}>
				<Flex align="center" className="gap-4 pointer" onClick={() => toggleOpen()}>
					{replies.length
						? (
							<>
								<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.Green700)}>{replies.length} replies</Typography.Text>
							</>
						)
						: (
							<>
								<img src={messageIcon} alt="message"/>
								<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.Green700)}>Reply</Typography.Text>
							</>
						)}
				</Flex>
			</ActionRestrictor>
		</Flex>
	);
};

export const CommentWrapper: FC<PropsWithChildren> = ({ children }) => {
	return <div className="comment">{children}</div>;
};
