import { useAtom } from "@reatom/npm-react";
import { FC, PropsWithChildren } from "react";

import { RightSidebar } from "@/shared/ui";

import { TRightSidebarView } from "../../lib";
import { characterSidebarView } from "../../model";

export const RightSidebarCharacter: FC<PropsWithChildren> = ({ children }) => {
	const [sidebarView, changeView] = useAtom(characterSidebarView);

	const sidebarGuestList: { title: string, value: TRightSidebarView }[] = [
		{
			value: "comments",
			title: "Comments"
		}
	];

	return (
		<RightSidebar<TRightSidebarView>
			changeView={changeView}
			sidebarViewList={sidebarGuestList}
			activeView={sidebarView as TRightSidebarView}
		>
			{children}
		</RightSidebar>
	);
};
