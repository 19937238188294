export function timeAgo (input: number | Date) {
	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

	let date : Date;
	if (input instanceof Date) {
		date = new Date(input.toLocaleString("en", { timeZone: tz }));
	} else {
		date = new Date(new Date(input).toLocaleString("en", { timeZone: tz }));
	}

	const now = new Date(new Date().toLocaleString("en", { timeZone: tz }));
	const timezoneOffsetInMs = date.getTimezoneOffset() * 60 * 1000;
	const diffInSeconds = (date.getTime() - timezoneOffsetInMs - now.getTime()) / 1000;

	const formatter = new Intl.RelativeTimeFormat("en");
	const ranges = [
		["years", 3600 * 24 * 365],
		["months", 3600 * 24 * 30],
		["weeks", 3600 * 24 * 7],
		["days", 3600 * 24],
		["hours", 3600],
		["minutes", 60],
		["seconds", 1]
	] as const;

	for (const [rangeType, rangeVal] of ranges) {
		if (Math.abs(diffInSeconds) > rangeVal) {
		  const delta = diffInSeconds / rangeVal;
		  return formatter.format(Math.round(delta), rangeType);
		}
	  }

	return "now";
}
